import React, { useState, useEffect } from 'react';
import { Connection, PublicKey, SystemProgram, clusterApiUrl } from '@solana/web3.js';
import * as anchor from '@coral-xyz/anchor';
import { Program, AnchorProvider, web3 } from '@coral-xyz/anchor';
import idl from './leaderboard-idl.json';
import kp from './keypair.json'
import './App.css';
import { Table, Button, Container, Row, Col, Form } from 'react-bootstrap';

// Constants
//const TWITTER_HANDLE = '_buildspace';
//const TWITTER_LINK = `https://twitter.com/${TWITTER_HANDLE}`;
const programId = new PublicKey("5Y4sE91z8vFxsggTnGrbi9nUMeaqnpTUj76rQ6BoFWhf");
const network = clusterApiUrl('devnet');
//const network = 'http://localhost:8899';

// Controls how we want to acknowledge when a transaction is "done".
const opts = {
  preflightCommitment: "processed"
}
const connection = new Connection(network, opts.preflightCommitment);

// Create a keypair for the account that will hold the leaderboard data.

const arr = Object.values(kp._keypair.secretKey)
const secret = new Uint8Array(arr)
const leaderboardAccount = web3.Keypair.fromSecretKey(secret)

const App = () => {
  const [walletAddress, setWalletAddress] = useState(null);
  const [name, setName] = useState('');
  const [score, setScore] = useState('');
  const [leaderboard, setLeaderboard] = useState([]);
  const [provider, setProvider] = useState(null);
  const [program, setProgram] = useState(null);
  const [leaderboardPublicKey, setLeaderboardPublicKey] = useState(null);

  const checkIfWalletIsConnected = async () => {
    if (window?.solana?.isPhantom) {
      console.log('Phantom wallet found!');
      const response = await window.solana.connect({ onlyIfTrusted: true });
      console.log(
        'Connected with Public Key:',
        response.publicKey.toString()
      );

      /*
       * Set the user's publicKey in state to be used later!
       */
      setWalletAddress(response.publicKey.toString());
    } else {
      alert('Solana object not found! Get a Phantom Wallet 👻');
    }
  };
  
    /*
     * When our component first mounts, let's check to see if we have a connected
     * Phantom Wallet
     */
    useEffect(() => {
      const onLoad = async () => {
        console.log("check if wallet connected");
        await checkIfWalletIsConnected();
      };
      window.addEventListener('load', onLoad);
      return () => window.removeEventListener('load', onLoad);
    }, []);



  const connectWallet = async () => {
    try {
      const response = await window.solana.connect();
      console.log('Connected with Public Key:', response.publicKey.toString());
      setWalletAddress(response.publicKey.toString());
      await initializeProgram();
    } catch (error) {
      console.error('Error connecting to wallet:', error);
    }
  };

  const initializeProgram = async () => {
    const provider = new anchor.AnchorProvider(connection, window.solana, opts.preflightCommitment);
    anchor.setProvider(provider);
    setProvider(provider);

    const program = new anchor.Program(idl, programId, provider);
    setProgram(program);
    const leaderboardPubKey = await initializeLeaderboard(program, provider);
    setLeaderboardPublicKey(leaderboardPubKey);
    fetchLeaderboard(program, leaderboardPubKey);
  };

  const initializeLeaderboard = async (program, provider) => {
    //const leaderboard = anchor.web3.Keypair.generate();
    try {
      await program.rpc.initialize({
        accounts: {
          leaderboard: leaderboardAccount.publicKey,
          user: provider.wallet.publicKey,
          systemProgram: SystemProgram.programId,
        },
        signers: [leaderboardAccount],
      });
      console.log('Leaderboard initialized:', leaderboardAccount.publicKey.toString());
      return leaderboardAccount.publicKey;
    } catch (error) {
      console.error('Error initializing leaderboard:', error);
      return null;
    }
  };

  const submitScore = async () => {
    if (!name || !score) {
      alert('Please enter a name and score');
      return;
    }
    const parsedScore = parseInt(score);
    if (isNaN(parsedScore)) {
      alert('Score must be a number');
      return;
    }
    try {
      await program.rpc.submitScore(name, new anchor.BN(parsedScore), {
        accounts: {
          leaderboard: leaderboardAccount.publicKey,
          user: provider.wallet.publicKey,
        },
      });
      fetchLeaderboard(program, leaderboardAccount.publicKey);
    } catch (error) {
      console.error('Error submitting score:', error);
    }
  };

  const getLeaderboard = async() => {
    console.log("here we are");
    try {
      console.log("getting program");

      const provider = new anchor.AnchorProvider(connection, window.solana, opts.preflightCommitment);
      anchor.setProvider(provider);
      setProvider(provider);
  
      const program = new anchor.Program(idl, programId, provider);
      setProgram(program);
      //const program = await getProgram(); 
      console.log("program: ",program)
      fetchLeaderboard(program, leaderboardAccount.publicKey);
      
      //console.log("Got the account", account)
      //setGifList(account.gifList)
  
    } catch (error) {
      console.log("Error in getGifList: ", error)
      setLeaderboard(null);
    }
  }

  const fetchLeaderboard = async (program, leaderboardPubKey) => {
    try {
      console.log("account leaderboard",program.account.leaderboard);
      console.log("pub key for leaderboard",leaderboardPubKey);
      const account = await program.account.leaderboard.fetch(leaderboardPubKey);
      console.log("account",account);
      console.log("players",account.players);
      const players = account.players.map(player => ({
        wallet: player.wallet.toString(),
        name: player.name,
        score: player.score.toNumber(),
      }));
      setLeaderboard(players);
    } catch (error) {
      console.error('Error fetching leaderboard:', error);
      setLeaderboard(null);
    }
  };

  const renderNotConnectedContainer = () => (
    <button
      className="cta-button connect-wallet-button"
      onClick={connectWallet}
    >
      Connect to Wallet
    </button>
  );
  const renderConnectedContainer = () => {
    if (leaderboard === null) {
      return (
        <div className="connected-container">
          <button className="cta-button submit-gif-button" onClick={initializeProgram}>
            Do One-Time Initialization For Leaderboard Program account
          </button>
        </div>
      )
    } 
    else {

    return (<>
      <div>
        <h2>Submit Score</h2>
        <Form>
          <Form.Group className="mb-3" controlId="formName">
            <Form.Label>Name</Form.Label>
            <Form.Control type="text" placeholder="Enter your name" value={name} onChange={(e) => setName(e.target.value)} />
          </Form.Group>
          <Form.Group className="mb-3" controlId="formScore">
            <Form.Label>Score</Form.Label>
            <Form.Control type="text" placeholder="Enter your score" value={score} onChange={(e) => setScore(e.target.value)} />
          </Form.Group>
          <Button variant="primary" onClick={submitScore}>
            Submit
          </Button>
        </Form>
      </div>
      <div>
        <h2 className="mt-4">Leaderboard</h2>
        <Button variant="secondary" onClick={() => fetchLeaderboard(program, leaderboardAccount.publicKey)}>
          Refresh Leaderboard
        </Button>
        <Table striped bordered hover className="mt-3">
          <thead>
            <tr>
              <th>Wallet</th>
              <th>Name</th>
              <th>Score</th>
            </tr>
          </thead>
          <tbody>
            {leaderboard.map((player, index) => (
              <tr key={index}>
                <td>{player.wallet}</td>
                <td>{player.name}</td>
                <td>{player.score}</td>
              </tr>
            ))}
          </tbody>
        </Table>
      </div></>
      )
    }
  }
  useEffect(() => {
    if (walletAddress) {
      console.log('wallet address triggered Fetching leaderboard...');
      getLeaderboard();
    }
  }, [walletAddress]);

  return (
    <div className="App">
      <Container>
        <Row className="justify-content-md-center">
          <Col xs lg="6">
            <div className="header-container text-center">
              <h1 className="header">Solana Leaderboard</h1>
              <p className="sub-text">Submit your score and view the leaderboard</p>
              {!walletAddress ? renderNotConnectedContainer() : renderConnectedContainer()}
             
            </div>
            
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default App;
